import { ref } from 'vue';
import { projectFirestore } from '../firebase/config';

const useDocument = (collection, id) => {
    let error = ref(null);
    let isPending = ref(false);
    let docRef = projectFirestore.collection(collection).doc(id);

    const deleteSubCollection = async (subCollection) => {
        isPending.value = true;
        error.value = null;

        try {
            await docRef.collection(subCollection).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    docRef.collection(subCollection).doc(doc.id).delete();
                });
            });
        } catch (err) {
            console.log(err.message);
            isPending.value = false;
            error.value = "Could not delete the subcolleciton: " + err.message;
        }

        isPending.value = false;

    };

    const deleteDoc = async () => {
        isPending.value = true;
        error.value = null;

        try {
            const res = await docRef.delete();
            isPending.value = false;
            return res;

        } catch (err) {
            console.log(err.message);
            isPending.value = false;
            error.value = "Could not delete the document: " + err.message;
        }
    };

    const updateDoc = async (updates) => {
        isPending.value = true;
        error.value = null;

        try {
            const res = await docRef.update(updates);
            isPending.value = false;
            return res;

        } catch (err) {
            console.log(err.message);
            isPending.value = false;
            error.value = "Could not update the document: " + err.message;
        }
    };



    return { error, isPending, deleteDoc, deleteSubCollection, updateDoc };
};

export default useDocument;
